/****
 * Generic Custom Javascript Access Method Output handler
 *
 * This will be used for any customjavascript access method which does not define a type and a more specific handler.
 *
 * For it to function, the customjavascript access method must return an object in this format:
     return {
        has_access:false, // boolean that reports whether the user should be granted access
        data:{
            type:'custom_javascript', // type to tell the script which output handler to use
            name:'Custom JS', // name of the custom access method to be displayed to the user
            class_name: 'custom-class-name', // custom class name of element displayed in the list of access methods
            description: 'description of custom method', // description text to be displayed in the access method
            purchase_text: "Get Started", // text for the purchase button for the access method
            purchase_url: 'url of the purchase button' // url to be provided to the user to allow them to gain access 
        }
    };
 */

window.TNCMS = window.TNCMS || {};
window.TNCMS.Access = TNCMS.Access || [];
window.__tnt || (window.__tnt = {});
window.__tnt.subscription || (__tnt.subscription = {});
window.__tnt.subscription.access || (__tnt.subscription.access = []);
window.__tnt.subscription.access.output || (__tnt.subscription.access.output = {});

__tnt.subscription.access.output.custom_javascript = function(method){
    var modalEl = document.getElementById('service-promo-modal'),
        methodsEl = null,
        servicePanelClasses = "promotion-service subscription-service panel panel-default",
        refererUrl = '',
        offersEl = document.getElementById('access-offers-modal'),
        template = null,
        wrapper = null;

    if(modalEl){ methodsEl = modalEl.querySelector('.access-methods-list') }
    if(offersEl){ 
        methodsEl = offersEl.querySelector('.access-methods-list');
        wrapper = document.getElementById('access-methods');
    }else{
        template = methodsEl.querySelector('template.template-promo-service');
        wrapper = methodsEl.querySelector('.output');
    }
        
    if(wrapper){ refererUrl = wrapper.getAttribute('data-referer') }

    if(method.response){
        var customClassName = "";

        if(method.response.class_name){ customClassName = " " + method.response.class_name; }

        /*
         * Elements to modify within the template element before it is added to the DOM and displayed to the user.
         */
        var elements = [
            {
                'element' : '.subscription-service.panel',
                'attr' : [
                    {
                        'attr' : 'class',
                        'value' : servicePanelClasses + customClassName
                    }
                ]
            },{
                'element' : '.method-header',
                'html' : method.response.name
            },{
                'element' : '.service-note',
                'html': method.response.note
            },{
                'element' : '.method-description',
                'html' : method.response.description
            },{
                'element' : 'a.method-link',
                'html' : method.purchase_text,
                'attr': [
                    {
                        'attr' : 'data-subscription-service',
                        'value' : method.response.service_id
                    },{
                        'attr' : 'data-name',
                        'value' : method.response.name
                    },{
                        'attr' : 'href',
                        'value' : method.response.purchase_url + '&referer_url=' + refererUrl
                    }
                ]
            }
        ];
        
        if(offersEl){
            elements.push({
                'element':'.offer-details',
                'html': method.response.description
            });
        }
    
        /*
         * Callback to run after modifying elements within the template
         *
         * @prop t - document fragment from the template element with values from elements inserted
         */
        callback = function(t){ }

    return { elements: elements, callback: callback };
 
    } else { return { elements: [], callback: function(){ return false; } } }
}
